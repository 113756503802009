import axios from 'axios';

export const getLocation = async (url: string): Promise<any> => {
  const query = `?&api_version=20210218&fields[locations]=name`;
  const endpoint = `${url}${query}`;
  const response = await axios.get(endpoint, {
    headers: {
      Authorization: `Bearer ${process.env.GATSBY_TEAMTAILOR_API_TOKEN}`,
      'X-Api-Version': `${process.env.GATSBY_TEAMTAILOR_API_VERSION}`,
    },
  });

  return response;
};
