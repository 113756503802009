/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { CareersPage } from '@/types';
import CareersStyled from '@/components/styled/CareersStyled';
import ContentfulRawToComponent from '@/components/utils/ContentfulRawToComponent';
import { getLocation } from '@/http-request/teamtailor-request';
import { usePageMeta } from '@/components/app/hooks';
import Reveal from '@/components/reveal/Reveal';

const careers: React.FC<CareersPage> = ({ data }) => {
  const [job, setJob] = useState(String);
  const [jobList, setJobList] = useState(null);
  const ionaTeamTailor = process.env.GATSBY_TEAMTAILOR_URL;

  const checkLocation = (location: string) => {
    if (
      location.toLowerCase().includes(`philippines`) ||
      location.toLowerCase().includes(`davao`)
    ) {
      setJob(`Philippines`);
    }
  };

  const processJobLocationLink = (link: string) => {
    try {
      const postedJobs = getLocation(link).then(
        (res: {
          status: number;
          data: { data: { attributes: { name: any } }[] };
        }) => {
          if (res.status === 200) {
            checkLocation(res.data.data[0].attributes.name);
            return res.data.data[0].attributes.name;
          }
          return res.data.data[0].attributes.name;
        },
      );
      return postedJobs;
    } catch (error) {
      return error.response;
    }
  };

  useEffect(() => {
    const postedJobs = data.allTeamTailorJob.nodes.map((node) => {
      const list = {
        slug: node.slug,
        title: node.attributes.title,
        location: `Anywhere`, // node.relationships.locations.links.related,
      };
      processJobLocationLink(list.location);
      if (job.length > 0) {
        list.location = job;
      }
      return list;
    });
    if (postedJobs.length) {
      // postedJobs[0].location.toLocaleLowerCase().includes(`philippines`)
      setJobList(postedJobs);
    }
  }, [job]);

  return (
    <CareersStyled>
      {usePageMeta(data.contentfulCareers.meta)}
      <div className="careers content">
        <Reveal className="page-title h1-title container">
          <h1>Careers</h1>
        </Reveal>
        <section className="intro-section">
          <div className="intro">
            <div className="container">
              <Reveal className="image-container hero-image-container">
                <GatsbyImage
                  image={getImage(
                    data.contentfulCareers.featuredImage.image.gatsbyImageData,
                  )}
                  alt={data.contentfulCareers.featuredImage.imageAlt}
                />
              </Reveal>
              <div className="row">
                <Reveal className="col-2 col-t-2 col-m-2 main-content-text">
                  <ContentfulRawToComponent
                    raw={data.contentfulCareers.mainContent.raw}
                  />
                  <div className="contact-us">
                    <div className="image-container contact-us-image-container">
                      <GatsbyImage
                        image={getImage(
                          data.contentfulCareers.contactUsImage.image
                            .gatsbyImageData,
                        )}
                        alt={data.contentfulCareers.contactUsImage.imageAlt}
                      />
                    </div>
                    <div className="contact-us-message">
                      <ContentfulRawToComponent
                        raw={data.contentfulCareers.contactUsMessage.raw}
                      />
                    </div>
                  </div>
                </Reveal>
                <Reveal className="col-2 col-t-2 col-m-2 main-content-image">
                  <div className="image-container main-image-container">
                    <GatsbyImage
                      image={getImage(
                        data.contentfulCareers.mainContentImage.image
                          .gatsbyImageData,
                      )}
                      alt={data.contentfulCareers.mainContentImage.imageAlt}
                    />
                  </div>
                </Reveal>
              </div>
            </div>
          </div>

          <div className="positions">
            <div className="container">
              <div className="row">
                <div className="col-2 col-t-2 col-m-2">
                  <Reveal className="h2" element="h2">
                    Open positions
                  </Reveal>
                </div>
                <div className="col-2 col-t-2 col-m-2">
                  <ul>
                    {data.contentfulCareers.positions.map((_position) => (
                      <Reveal key={_position.slug} element="li">
                        <Link to={`/careers/${_position.slug}`}>
                          <h3 className="position-title">{_position.title}</h3>
                          <div className="position-sub">
                            <span className="position-location">
                              {_position.location}
                            </span>
                            <button type="button" className="link apply">
                              Apply
                            </button>
                          </div>
                        </Link>
                      </Reveal>
                    ))}

                    {jobList &&
                      jobList.map((node) => (
                        <Reveal key={node.slug} element="li">
                          <a href={`${ionaTeamTailor}/jobs/${node.slug}`}>
                            <h3 className="position-title">{node.title}</h3>
                            <div className="position-sub">
                              <span className="position-location">
                                {node.location}
                              </span>
                              <button type="button" className="link apply">
                                Apply
                              </button>
                            </div>
                          </a>
                        </Reveal>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {data.allContentfulCareerSection.nodes.reverse().map((_section) => {
          const hasFeaturedBlock =
            _section?.featuredImage !== null &&
            _section?.featuredContent != null;
          // const bgGrey = data.allContentfulCareerSection.nodes.length % 2 === 1;
          const ourValuesSection = _section.title === `Our values`;
          return (
            <section
              className={`blocks-section${ourValuesSection ? ` bg-grey` : ``}`}
              key={_section.id}
            >
              <div
                className={`container blocks-section-container${
                  hasFeaturedBlock ? ` has-featured-block` : ``
                }`}
              >
                {hasFeaturedBlock && (
                  <div className="row section-featured-content">
                    <div className="col-2 col-t-2 col-m-2">
                      <Reveal className="image-container featured-block-image-container">
                        <GatsbyImage
                          image={getImage(
                            _section.featuredImage.image.gatsbyImageData,
                          )}
                          alt={_section.featuredImage.imageAlt}
                        />
                      </Reveal>
                    </div>
                    <Reveal className="col-2 col-t-2 col-m-2 content">
                      <ContentfulRawToComponent
                        raw={_section.featuredContent.raw}
                      />
                    </Reveal>
                  </div>
                )}
                <Reveal element="h2">{_section.title}</Reveal>
                <div className="row section-blocks">
                  {_section.blocks.map((_block) => (
                    <div
                      className="col-2 col-t-2 col-m-2 section-block"
                      key={_block.id}
                    >
                      <Reveal className="row">
                        <div className="col-2 col-t-1 col-m-1">
                          <div className="image-container block-image-container">
                            <GatsbyImage
                              image={getImage(
                                _block.image.image.gatsbyImageData,
                              )}
                              alt={_block.image.imageAlt}
                            />
                          </div>
                        </div>
                        <div className="col-2 col-t-1 col-m-1 col-block-text-container">
                          <div className="block-text-container darker-text ">
                            <h3 className="h4 block-title">{_block.title}</h3>
                            <ContentfulRawToComponent
                              raw={_block.content.raw}
                            />
                          </div>
                        </div>
                      </Reveal>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          );
        })}
      </div>
    </CareersStyled>
  );
};

export const query = graphql`
  query PageQuery {
    allContentfulCareerSection {
      nodes {
        id
        title
        featuredContent {
          raw
        }
        featuredImage {
          imageAlt
          image {
            gatsbyImageData
          }
        }
        blocks {
          id
          title
          content {
            raw
          }
          image {
            imageAlt
            image {
              gatsbyImageData
            }
          }
        }
      }
    }
    contentfulCareers {
      title
      positions {
        slug
        title
        location
      }
      mainContent {
        raw
      }
      contactUsImage {
        image {
          gatsbyImageData
        }
        imageAlt
      }
      contactUsMessage {
        raw
      }
      mainContentImage {
        imageAlt
        image {
          gatsbyImageData
        }
      }
      featuredImage {
        imageAlt
        image {
          gatsbyImageData
        }
      }
      meta {
        title
        description
        type
        image {
          file {
            url
          }
        }
      }
    }
    allTeamTailorJob {
      nodes {
        attributes {
          title
        }
        slug
        relationships {
          locations {
            links {
              related
            }
          }
        }
      }
    }
  }
`;

export default careers;
